import * as React from "react"


// markup
const IndexPage = () => {
  return (
    <main>
      <h1>Under construction</h1>
    </main>
  )
}

export default IndexPage
